<template>
    <div>
        <base-header
        type="secondary"
        class="pb-6 pb-8 pt-2 pt-md-7">
        <h2>{{ $t('autoApproveUserList.filter') }}</h2>

        <div class="row">
            <div class="col-12 col-md-3 pt-2">
                <base-input :placeholder="$t('autoApproveUserList.filters.text')"
                    v-model="name">
                </base-input>
            </div>
            <div class="col-12 col-md-2 pt-2">
                <base-button type="default-button" @click="search()">{{ $t('autoApproveUserList.filters.search') }}</base-button>
            </div>
        </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <card header-classes="bg_transparent" body-classes="d-flex">
                        <div slot="header" class="row align-items-center">
                            <div class="col-md-6">
                                <h5 class="h3 mb-0">{{ $t('autoApproveUserList.title') }}</h5>
                            </div>
                        </div>
                        <table class="user-table table-bordered table-hover">
                            <thead>
                                <th>{{ $t('autoApproveUserList.table.userName') }}</th>
                                <th>{{ $t('autoApproveUserList.table.address') }}</th>
                                <th>{{ $t('autoApproveUserList.table.actions') }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in usersFiltered" :key="index">
                                    <td>{{ user.username }}</td>
                                    <td>{{ user.address }}</td>
                                    <td class="remove"><i @click="removeUser(user)" class="fas fa-user-minus cursor-pointer"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <div slot="footer" class="align-items-right" style="padding: 0">
                            <b-pagination class="no-border"
                                v-model="currentPage"
                                :total-rows="users.length"
                                :per-page="perPage"
                                align="right"
                                style="padding: 0"
                                hide-goto-end-buttons>
                            </b-pagination>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        methods: {
            search: function() {
                let filters = []

                if (this.name.trim() !== '') {
                    filters.push('username=' + this.name.trim())
                }

                this.$store.dispatch('getAutoapproveUsers', filters)
            },
            removeUser: function(user) {
                try {
                    this.$confirm('The further videos uploaded by the user won\'t be auto approved, do you want to continue?')
                        .then(() => {
                            this.$store.dispatch('removeAutoapprove', user)
                                .then(() => {
                                    this.search()
                                })
                        })
                } catch (error) {
                    this.$alert(
                        'Something went wrong, Please try again',
                        'Warning',
                        'warning')
                }
            }
        },
        computed: {
            users() {
                return this.$store.state.stats.users
            },
            usersFiltered () {
                return this.$store.getters.getUsers.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
            }
        },
        mounted () {
            this.search()
        },
        data: function() {
            return {
                name: '',
                currentPage: 1,
                perPage: 20
            }
        }
    }
</script>

<style scoped>
    .user-table{
        width: 100%;
        margin-left: 1px;
        margin-right: 1px;
        float: none;
    }

    thead th {
        background-color: #7a92aa;
    }

    tbody tr:hover {
      background: rgba(122, 146, 170, .40)
    }

    td {
        text-align: left;
        padding-left: 2%;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    th {
        color: white;
        text-align: left;
        padding-left: 2%;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .remove {
        padding-left: 4% !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>